import { API } from 'aws-amplify';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

export const CallsWaiting = ({ hubSpotId, queueNumber, reTake }) => {
  const [loading, setLoading] = useState(true);
  const [calls, setCalls] = useState(0);
  const sumCalls = (response) => {
    return (
      parseInt(response.attributes.ApproximateNumberOfMessages) +
      parseInt(response.attributes.ApproximateNumberOfMessagesDelayed) +
      parseInt(response.attributes.ApproximateNumberOfMessagesNotVisible)
    );
  };
  useEffect(() => {
    const myAPI = 'dialer';
    const path = `/v2/queues/${hubSpotId}/number/${queueNumber}`;
    API.get(myAPI, path, {})
      .then((response) => {
        setCalls(sumCalls(response));
      })
      .catch((error) => {
        console.error('Error:', JSON.stringify(error));
      })
      .finally(() => setLoading(false));
  }, [hubSpotId, queueNumber, reTake]);
  return (
    <div>
      {loading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <div>{calls}</div>
      )}
    </div>
  );
};
