import React from 'react';
import PurgeQueue from './PurgeQueue';
import { Space, Tag, Tooltip } from 'antd';
import { CallsWaiting } from './CallsWaiting';

export function Columns(hubSpotId, queues, reTake) {
  if (!queues) {
    return [
      {
        title: 'Cargando...',
        dataIndex: 'loading',
        key: 'loading',
      },
    ];
  }
  const filterQueue = queues.map(({ queueNumber, label }) => ({
    text: `${queueNumber} ${label}`,
    value: queueNumber,
  }));
  return [
    {
      title: 'Cola de llamada',
      dataIndex: 'queueNumber',
      filters: filterQueue,
      onFilter: (value, record) => record.queueNumber.indexOf(value) === 0,
      render: (text, record) => (
        <Space size={'small'}>
          <div>
            <h3 className="text-base font-semibold" style={{ margin: 'auto' }}>
              <Tag>{text}</Tag> {record.label}
            </h3>
          </div>
        </Space>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'isActive',
      render: (_, { isActive }) => (
        <>
          <Tag color={isActive === true ? 'green' : 'volcano'} key={isActive}>
            {isActive ? 'Live' : 'Dissabled'}
          </Tag>
        </>
      ),
    },
    {
      title: 'Llamadas en cola',
      dataIndex: 'label',
      render: (_, { queueNumber, hubSpotId }) => (
        <>
          <CallsWaiting
            hubSpotId={hubSpotId}
            queueNumber={queueNumber}
            reTake={reTake}
          />
        </>
      ),
    },
    {
      title: 'Opciones',
      dataIndex: 'label',
      render: (label, record) => (
        <span>
          <Tooltip title="Eliminar Cola">
            <PurgeQueue
              hubSpotId={hubSpotId}
              label={label}
              queue={record.queueNumber}
            />
          </Tooltip>
        </span>
      ),
    },
  ];
}
