import React from 'react';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

export default function PageHeader({
  children,
  title = 'Incio',
  head = 'Inicio',
  hrefHead = '/',
}) {
  return (
    <div>
      <div className="md:flex justify-between items-center">
        <div>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={'/'}>
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: <Link to={hrefHead}>{head}</Link>,
              },
            ]}
          />
          <h2>{title}</h2>
        </div>
        <div>{children}</div>
      </div>
      <Divider />
    </div>
  );
}
