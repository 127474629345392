/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { Card, Layout, Menu, Space, message } from 'antd';
import {
  HomeOutlined,
  PhoneOutlined,
  RobotOutlined,
  BookOutlined,
  AuditOutlined,
  ApiOutlined,
  CommentOutlined,
  CoffeeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import useAuth from '../../hooks/useAuth';
import filterArrays from '../../utils/filterArrays';
import matchProducts from '../../services/matchProducts';
import Loading from './Loading';
import AppHeader from './components/Header';

const { Content, Sider, Footer } = Layout;

export default function PrivateLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productsClient, setProductsClient] = useState([]);
  const [productsMissing, setProductsMissing] = useState([]);

  const iconsArray = {
    NTP: <BookOutlined />,
    NTI: <ApiOutlined />,
    NTU: <RobotOutlined />,
    NTHSVOI: <PhoneOutlined />,
    NTHSSMS: <CommentOutlined />,
    NTAM: <AuditOutlined />,
    NTS: <CoffeeOutlined />,
    NTPV: <CheckCircleOutlined />,
    NTRV: <RobotOutlined />,
  };

  const navigatePath = (path) => {
    if (!path) {
      const PathString = path.toLowerCase();
      const PathFormateado = PathString.replace(/\s+/g, '-');
      navigate(PathFormateado);
    }
  };

  async function filterClientServices() {
    try {
      setLoading(true);
      const [clientResponse, products] = await matchProducts(user);
      sessionStorage.setItem(
        'client',
        JSON.stringify(clientResponse.clientItems)
      );
      if (
        !clientResponse.clientItems.products ||
        clientResponse.clientItems?.products.length === 0
      ) {
        setProductsMissing(products.results);
      }
      const [arrOwn, arrMissing] = filterArrays(
        clientResponse.clientItems.products,
        products.results
      );
      // @ts-ignore
      setProductsClient(arrOwn);
      sessionStorage.setItem('productsClient', JSON.stringify(arrOwn));
      // @ts-ignore
      setProductsMissing(arrMissing);
      sessionStorage.setItem('productsMissing', JSON.stringify(arrMissing));
    } catch (error) {
      message.error('Ha ocurrido un error');
      console.error(error.message || error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    filterClientServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = [
    {
      key: '/home',
      icon: <HomeOutlined />,
      label: <Link to="/home">Inicio</Link>,
    },
    ...productsClient.map((item) => ({
      key: item.productId,
      icon: iconsArray[item.productId],
      label: item.comercialName,
      children: item.views.map((task) => ({
        key: task.path,
        label: <Link to={task.path}>{task.name}</Link>,
      })),
    })),
    ...productsMissing.map((item) => ({
      key: `service/${item.comercialName}`,
      icon: iconsArray[item.productId],
      label: (
        <Link to={`service/${item.comercialName}`}>{item.comercialName}</Link>
      ),
    })),
  ];

  return loading ? (
    <Loading />
  ) : (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader className="app-header" />
      <Layout style={{ padding: '0px' }}>
        <Sider
          width="180px"
          theme="dark"
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          style={{ paddingTop: '10px', alignContent: 'left' }}
        >
          <Menu
            key="sideBarMenu"
            theme="dark"
            mode="inline"
            onClick={(e) => navigatePath(e.key)}
            style={{ textAlign: 'left' }}
            items={menuItems}
          />
        </Sider>
        <Content
          className="container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              padding: '10px',
            }}
            align="center"
          >
            <Card>
              <Outlet />
            </Card>
          </Space>
          <Footer
            style={{
              textAlign: 'right',
              paddingTop: '0px',
              position: 'sticky',
              bottom: 0,
              zIndex: 10,
            }}
          >
            ©{new Date().getFullYear()} Created by Nua
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
}
